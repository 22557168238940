import tttrang from "./tttrang.png";
import hvtdung from "./hvtdung.jpg";
import razvan from "./Razvan.png";
const keynote = [
  {
    img: razvan,
    name: "Assoc.Prof. Razvan Beuran",
    scope: "Information Technology",
    detail: "Applications of AI/ML to Cybersecurity",
    link: "/keynote",
  },
  {
    img: hvtdung,
    name: "Dr. Huynh Vo Trung Dung",
    scope: "Electronics Engineering",
    detail:
      "The Synergy of Blockchain and AI in 6G Networks: Opportunities, Challenges, and Prospective Pathways.",
    link: "/keynote",
  },
  {
    img: tttrang,
    name: "Ms. Ta Thuy Trang",
    scope: "Civil Engineering",
    detail:
      "Machine Learning Algorithms for Predicting Compressive Strength of Normal and High-Early Strength Concrete: A case Study in Binh Thuan, Viet Nam",
    link: "/keynote",
  },
];
export default keynote;
