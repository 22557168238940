const TimelineEntry = ({ date, newDate, title, isExtended }) => {
  return (
    <div className="relative pl-8 sm:pl-32 py-2 md:py-4 group">
      <div className="flex flex-col sm:flex-row items-start group-last:before:hidden before:absolute before:left-7 sm:before:left-5 before:h-full before:px-px before:bg-slate-300 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-7 sm:after:left-5 after:w-2 after:h-2 after:bg-indigo-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5">
        <div className="sm:absolute left-0 translate-y-0.5 inline-flex flex-col items-center justify-center">
          <time
            className={`text-sm font-bold uppercase w-[110px] h-6 mb-1 sm:mb-0 ${
              isExtended ? "line-through text-red-600" : "text-emerald-600"
            } bg-emerald-100 rounded-full ml-4 lg:ml-0`}
          >
            {date}
          </time>
          {isExtended && (
            <time className="text-sm font-bold uppercase w-[110px] h-6 mb-3 sm:mb-0 text-emerald-600 bg-emerald-100 rounded-full ml-4 lg:ml-0">
              {newDate}
            </time>
          )}
        </div>
        <div className="text-xl font-[600] text-blue_super_dark ml-4">
          {title}
        </div>
      </div>
    </div>
  );
};

export const Timeline = () => {
  const timelineEvents = [
    {
      date: "20 Oct 2024",
      newDate: "30 Oct 2024",
      title: "Deadline for Full paper submissions (Extended)",
      isExtended: true,
    },
    {
      date: "10 Nov 2024",
      // newDate: "12 Nov 2024",
      title: "Acceptance / Rejection Notification",
      isExtended: false,
    },
    {
      date: "17 Nov 2024",
      title: "Deadline for registration",
      isExtended: false,
    },
    { date: "23 Nov 2024", title: "Conference Date", isExtended: false },
  ];

  return (
    <div className="my-2 mx-4 mb-8">
      {timelineEvents.map((event, index) => (
        <TimelineEntry
          key={index}
          date={event.date}
          newDate={event.newDate}
          title={event.title}
          isExtended={event.isExtended}
        />
      ))}
    </div>
  );
};
