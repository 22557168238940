import React from "react";
// import { Link } from "react-router-dom";
import QRImage from "./assests/images/QR-VCB-ATAC2024.png";
// import QRForm from "./assests/images/QR-Form-ATAC2024.png";
import newImg1 from "./new.gif";

const RegisterDetails = () => {
  return (
    <>
      <div className="flex justify-center ml-2">
        <div className="max-w-6xl p-6 leading-7">
          <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
            <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
              ATA
            </span>
            C 2024 Paper Template & Submission
          </p>
          <ul className="list-disc ml-6 mb-6 lg:mb-8 leading-relaxed">
            <li className="py-2">
              <a
                className="text-blue_dark cursor-pointer font-semibold flex items-center"
                href="https://www.ieee.org/conferences/publishing/templates.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                ATAC 2024 Paper Template Download from here
                <img className="h-4 ml-2" src={newImg1} alt="Submit Icon" />
              </a>
            </li>
            <li className="">
              <p className="mb-2 mt-4">
                <b>
                  Upload the full paper through the Conference submission
                  Platform: Microsoft CMT System
                  <br />
                  <a
                    href="https://cmt3.research.microsoft.com/ATAC2024"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue_dark hover:text-blue "
                  >
                    cmt3.research.microsoft.com/ATAC2024
                  </a>
                </b>
              </p>
              <a
                href="https://cmt3.research.microsoft.com/ATAC2024/"
                className="text-white bg-[#E30022] transition-transform transform-gpu hover:-translate-y-2 hover:shadow-lg py-2 my-4 px-4 rounded-full text-lg font-semibold"
                target="_blank"
                rel="noopener noreferrer"
              >
                Submission HERE
              </a>
            </li>
          </ul>
          <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
            <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
              Reg
            </span>
            istration:
          </p>
          <p className="mb-4">
            No papers received after the last date will be accepted.
          </p>
          <p className="mb-4">
            Original contributions based on the results of research and
            developments are solicited. Prospective authors are requested to
            submit their papers with a minimum of four pages and a maximum of
            six pages. All the accepted and presented papers will be published
            in Conference Proceedings indexed by recognized platforms.
          </p>
          <p className="mb-4">
            Participants without submitted papers or with unaccepted papers are
            still welcome to attend the conference and participate in all
            sessions and activities by completing the two-step registration
            process.
          </p>
          <p className="mb-4">
            The authors must agree that if the paper is accepted for
            presentation, at least one of the authors will register for the
            conference and present the paper by completing the two-step
            registration process as mentioned:
          </p>
          <p className="my-4 text-red-500  font-bold">
            <b>Note: </b> Each registration can only cover one presenter for one
            paper.
          </p>
          <p className="mb-4">
            <strong>Step 1: (Fee Submission)</strong>
            <br />
            Authors/Attendees are requested to pay the Registration Fee (via
            online payment mode only) to the following Bank Account:
          </p>
          <ul className="list-disc ml-6 mb-4">
            <li>
              Account name: <strong>NGUYEN NGOC MAI OANH (AIoT Lab VN)</strong>
            </li>
            <li>
              Account No.: <strong>QRGD000101855389402</strong>
            </li>
            <li>
              Bank:{" "}
              <strong>
                Ngân hàng TMCP Ngoại thương Việt Nam (Vietcombank)
              </strong>
            </li>
            <li>
              Payment format: <strong>ATAC2024 - [Name] - [Paper ID] </strong>
              (Paper ID is optional).{" "}
            </li>
          </ul>
          <img
            src={QRImage}
            alt="Bank QR Code"
            className="mt-4 h-[400px] ml-0"
          />

          <br />
          <table className="w-3/4 text-sm text-left rtl:text-right font-medium">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Fee
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="odd:bg-white even:bg-gray-50 border-b border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Normal registration
                </th>
                <td className="px-6 py-4">1,200,000 VNĐ</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-50 border-b border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  AIoT Lab VN member / STU students
                </th>
                <td className="px-6 py-4">600,000 VNĐ</td>
              </tr>
              <tr className="odd:bg-white even:bg-gray-50 border-b border-gray-700">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  Non-presenting Author / Other participants
                </th>
                <td className="px-6 py-4">600,000 VNĐ</td>
              </tr>
            </tbody>
          </table>
          <p className="mb-4">
            For International candidates: Contact the ATAC 2024 Conference
            Secretary.
          </p>
          <p className="mb-4">
            <strong>Step 2 (Fill Registration Form):</strong>
            <br />
            After making payment, authors are required to fill the
            Conference/Attendee Registration Form.
            <br />
            <a
              href="https://forms.gle/HVhuKqmBV2tPQHfY8"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue_dark font-semibold hover:text-black hover:underline"
            >
              Click here to fill the form: https://forms.gle/HVhuKqmBV2tPQHfY8
            </a>
          </p>

          {/* <img src={QRForm} alt="qr" className="mt-6 h-[200px]" /> */}
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScrkkDFTx3facoROeJDIjqNhTT31mLK6MLuG1YLjftYi6LFoA/viewform?embedded=true"
            width="640"
            height="800"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            className="mt-6"
          >
            Loading…
          </iframe>
          <p className="mb-4 text-red-500 mt-4">
            <b>
              Registration fees once paid will not be refunded under any
              circumstances.
            </b>
          </p>
        </div>
      </div>
    </>
  );
};

export default RegisterDetails;
