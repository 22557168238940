import React from "react";
import Body from "./pages/body";
import { Routes, Route } from "react-router-dom";
import Registration from "./pages/registration";
import { NavBar } from "./components/navbar";
import Committees from "./pages/committee";
import { AuthorGuidelines } from "./pages/author-guide";
import DeclareSoon from "./pages/declareSoon";
import Contact from "./pages/contact";
import ConferencePoster from "./components/ConferencePoster";
import Keynote from "./pages/keynote";
export default function HeroComponent() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Body />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/committees" element={<Committees />} />
        <Route path="/author-guide" element={<AuthorGuidelines />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/keynote" element={<Keynote />} />
        <Route path="/atac2024" element={<ConferencePoster />} />
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<DeclareSoon />} />
      </Routes>
    </>
  );
}
