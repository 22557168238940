import React, { useState } from "react";

function SponsorCard({ img, name, detail, author, scope, link }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex flex-col items-center w-[450px] h-[450px] md:w-[350px] lg:w-[400px] border border-gray-300 rounded-xl shadow-lg bg-white dark:bg-gray-50 dark:text-gray-800 p-2 overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex justify-center w-full h-48 mb-14 mt-2">
        <img
          src={img}
          alt={name}
          className="w-48 h-64 object-cover pb-[16px] rounded-md"
        />
      </div>
      <div className="flex flex-col items-center text-center space-y-2">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-800 truncate">
          {name}
        </h2>
        <p className="text-lg font-bold text-blue_dark dark:text-gray-600">
          {scope}
        </p>
        <p className="text-sm font-medium text-gray-700 dark:text-gray-600 overflow-hidden">
          {detail}
        </p>
      </div>

      {/* Hover Popup */}
      {isHovered && (
        <div className="absolute top-0 left-0 w-full h-full bg-white dark:bg-gray-800 bg-opacity-90 p-4 flex flex-col justify-center items-center shadow-lg text-center overflow-y-auto">
          <h3 className="text-lg font-bold mb-2 text-gray-900 dark:text-gray-200">
            {name}
          </h3>
          <p className="text-sm font-semibold text-gray-700 dark:text-gray-400 mb-2">
            {detail}
          </p>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 px-4 py-2 bg-blue_dark text-white hover:bg-blue-600 transition border rounded-md"
          >
            Detail
          </a>
        </div>
      )}
    </div>
  );
}

export default SponsorCard;
