import { Footer } from "../components/footer";
import Transition from "../utils/Transition";

const Contact = () => {
  return (
    <Transition>
      <div className="flex flex-col min-h-screen">
        {/* Main Content */}
        <div className="flex-grow my-36 px-4 lg:px-36">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Contact Details */}
            <div>
              <div className="bg-white w-full max-w-lg">
                <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
                  <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
                    Con
                  </span>
                  tact Details
                </p>
                <p className="text-gray-700">
                  <strong>Conference Chair:</strong> The 2024 Conference on
                  Applications of Technology, Automation and Civil Engineering
                  (ATAC 2024)
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>Location:</strong> Saigon Technology University (STU),
                  180 Cao Lo Str; Ward 4, Dist 8, HCMC
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:info@aiotlab.vn"
                    className="text-blue_super_dark hover:underline"
                  >
                    info@aiotlab.vn
                  </a>
                </p>
              </div>

              <div className="bg-white w-full max-w-lg mt-5">
                <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
                  <span className="underline underline-offset-8 decoration-4 decoration-[rgb(227,0,34)]">
                    Con
                  </span>
                  tact Person
                </p>
                <p className="text-gray-700">
                  <strong>1. Mr. Pham Duc Dat - AIoT Lab VN</strong>
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>SĐT:</strong> 0902.850.103
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:pddat@ai4ia.cc"
                    className="text-blue_super_dark hover:underline"
                  >
                    pddat@ai4ia.cc
                  </a>
                </p>

                <p className="text-gray-700 mt-6">
                  <strong>
                    2. Ms. Tran Thi My Hong - Saigon Technology University
                  </strong>
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>Điện thoại:</strong> 028 38 505 520 - 206
                </p>
                <p className="text-gray-700 mt-2">
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:qlkh@stu.edu.vn"
                    className="text-blue_super_dark hover:underline"
                  >
                    qlkh@stu.edu.vn
                  </a>
                </p>
              </div>
            </div>

            {/* Google Map */}
            <div className="w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16802.721248267782!2d106.6778321!3d10.7379972!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f62a90e5dbd%3A0x674d5126513db295!2sSaigon%20Technology%20University!5e1!3m2!1sen!2s!4v1729435596643!5m2!1sen!2s"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </Transition>
  );
};

export default Contact;
