import React from "react";
import newImg from "./new.gif";

const SubmissionGuidelines = () => (
  <div className="flex justify-center">
    <div className="max-w-6xl p-6">
      <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
        <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
          Submission of Paper
        </span>
      </p>
      <ul className="list-disc ml-6 leading-relaxed">
        <li className="py-2 font-[400]">
          Prospective authors are invited to submit full-length papers in PDF
          format via the Microsoft Conference Management Toolkit (CMT).
          <br />
          <a
            className="text-blue_super_dark cursor-pointer font-semibold"
            href="https://cmt3.research.microsoft.com/ATAC2024/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Submission Link: https://cmt3.research.microsoft.com/ATAC2024/
          </a>
        </li>
        <a
          href="https://cmt3.research.microsoft.com/ATAC2024/"
          className="text-white bg-[#E30022] transition-transform transform-gpu hover:-translate-y-2 hover:shadow-lg py-2 my-4 px-4 rounded-full text-lg font-semibold"
          target="_blank"
          rel="noopener noreferrer"
        >
          Submission HERE
        </a>
        <li className="py-2 font-[400]">
          All papers will be peer-reviewed using a blind review process.
          Accepted papers will be published in the conference proceedings with
          ISBN.
        </li>
        <li className="py-2 font-[400]">
          Submission of proposals for special sessions, tutorials and student
          sessions should be sent to the conference secretary.
        </li>
      </ul>
    </div>
  </div>
);

const GuidelinesForPreparation = () => (
  <div className="flex justify-center">
    <div className="max-w-6xl p-6">
      <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
        <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
          Guidelines for Manuscript Preparation
        </span>
      </p>
      <ul className="list-disc ml-6 leading-relaxed">
        <li className="py-2 font-[400]">
          The authors are advised to download the “IEEE Template files“ in MS
          Word or Latex format to prepare the paper. The best way to have your
          paper meet the format requirements is to copy and paste your paper
          into the template file.
          <br />
          <a
            className="text-blue_super_dark cursor-pointer font-semibold"
            href="https://www.ieee.org/conferences/publishing/templates.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download IEEE Template files here
          </a>
        </li>
        <li className="py-2 font-[400]">
          Only PDF format in Letter size (8.5×11 inches) is accepted for the
          camera-ready paper submission.
        </li>
        <li className="py-2 font-[400]">
          The standard page number of the paper is 4 pages. The maximum
          allowable number of pages is 6.
        </li>
        <li className="py-2 font-[400]">
          Do not number the page of your manuscript.
        </li>
        <li className="py-2 font-[400]">Do not use headers or footers.</li>
        <li className="py-2 font-[400]">
          Fonts: use only Times or Times Roman to edit your paper.
        </li>
        <li className="py-2 font-[400]">
          Title: The title should be in Times 24-point, normal type. Capitalize
          the first letter of nouns, pronouns, verbs, adjectives, and adverbs;
          do not capitalize articles, coordinate conjunctions, or prepositions
          (unless the title begins with such a word). The title should not be
          all in capital letters.
        </li>
        <li className="py-2 font-[400]">
          Author name: The names of the authors are to be centered beneath the
          title and printed in Times 11-point, normal type. Author’s name should
          not be preceded nor followed by author’s titles and society
          memberships.
        </li>
        <li className="py-2 font-[400]">
          Author Affiliation: The authors’ affiliations are to be centered
          beneath the author’s names and printed in Times 11-point, normal type.
          Affiliation should include: department/division, university/company,
          postal address, phone (optional), fax (optional), email (optional),
          and URL (optional). In the case of authors from different
          institutions, identify each institution with a number (starting from
          1). At the end of the name of each author place the institution
          identifier as a superscript. List the institutions on separate lines
          with the format described above. Each institution should be preceded
          by the corresponding identifier as a superscript.
        </li>
        <li className="py-2 font-[400]">
          Keywords: keywords are not necessary for the conference paper.
        </li>
        <li className="py-2 font-[400]">
          Figures: figures are centered in the column; large figures can be
          placed over two columns. The figure caption font size is 8-point.
        </li>
        <li className="py-2 font-[400]">
          Tables: tables are centered in the column; large tables can be placed
          over two columns. The table caption font size is 8-point as for
          figures.
        </li>
        <li className="py-2 font-[400]">
          Use of color: no color is used in the proceedings. Please transform
          your colored figures into grey-scale images before including them into
          the paper.
        </li>
        <li className="py-2 font-[400]">
          PDF requirement: please embed all local fonts into the PDF
          camera-ready paper. Only papers which are compatible with IEEE Xplore
          requirements can be accepted.
        </li>
      </ul>
    </div>
  </div>
);

const ManuscriptReviewProcess = () => (
  <div className="flex justify-center">
    <div className="max-w-6xl p-6">
      <p className="text-3xl font-bold mb-4 tracking-wide leading-normal">
        <span className="underline underline-offset-8 decoration-4 decoration-[#E30022]">
          Manuscript Review/Paper Acceptance Process
        </span>
      </p>
      <ul className="list-disc ml-6 leading-relaxed">
        <li className="py-2 font-[400]">
          Initial screening will be done to check compatibility with the
          conference theme & track.
        </li>
        <li className="py-2 font-[400]">
          Papers submitted in IEEE Two column format only will be considered for
          further processing.
        </li>
        <li className="py-2 font-[400]">
          Papers received in proper format will be checked for plagiarism to
          confirm originality of the submitted work. Manuscript with less than
          permissible plagiarism will be accepted for further processing;
          otherwise, it will be sent back to the author for revision.
        </li>
        <li className="py-2 font-[400]">
          All the manuscripts with permitted plagiarism will be sent for
          technical and quality review. Double review process is applicable for
          all papers. Reviewer's comments will be communicated to the respective
          authors. Manuscripts recommended for revision by the reviewers will be
          reverted to the respective author with a notification for
          re-submission within a week's time from the date of notification.
        </li>
        <li className="py-2 font-[400]">
          Manuscript approved by the reviewers will be considered for final
          presentation and publication process.
        </li>
        <li className="py-2 font-[400]">
          The acceptance of the manuscript for presentation in the conference
          will be notified to the author by the respective Co-chairs.
        </li>
      </ul>
      <p className="py-2 font-bold">
        "Authors must read the guidelines carefully and use the appropriate
        article template."
      </p>
    </div>
  </div>
);

const ATAC2024Submission = () => {
  return (
    <>
      <SubmissionGuidelines />
      <GuidelinesForPreparation />
      <ManuscriptReviewProcess />
    </>
  );
};

export default ATAC2024Submission;
