import { Organization } from "../components/Organization";
import { Guidelines } from "../components/Guidelines";
import { Hero } from "../components/Hero";
import { About } from "../components/about";
import { Timeline } from "../components/timeline";
import Sponsor from "../components/Sponsors/Sponsor";
// import chair from "../components/assests/chair/chair";
// import sessionChairs from "../components/assests/sessionChairs/sessionChairs";
import newImg from '../components/new.gif';
import { Link } from "react-router-dom";
import { Footer } from "../components/footer";
import keynote from "../components/assests/keynote/keynote";
import Transition from "../utils/Transition";

const Body = () => {
  const announcements = [
    {
      text: "Extended Deadline for Paper Submission: 30th October 2024",
      link: "/",
    },
    {
      text: "ATAC 2024 Paper Template Download from here",
      link: "https://www.ieee.org/conferences/publishing/templates.html",
    },
    {
      text: "Full submission paper process started",
      link: "/register",  // Sửa đường dẫn thành '/register'
    },
  ];

  return (
    <>
      <Transition>
      <div className="px-4 bg-white flex items-center justify-center"></div>
      <Hero />
      <div className="flex justify-center items-center lg:mx-24 mx-6 mt-2">
        <div className="relative flex overflow-x-hidden">
          <div className="flex py-4 animate-marquee whitespace-nowrap">
            {announcements.map((announcement, index) => (
              <span key={index} className="text-sm mx-4 flex items-center">
                <a
                  className="text-blue_dark font-semibold flex items-center"
                  href={announcement.link}
                >
                  {announcement.text}
                  <img className="h-4 ml-2" src={newImg} alt="Submit Icon" />
                </a>
              </span>
            ))}
          </div>
          <div className="flex py-4 animate-marquee whitespace-nowrap">
            {announcements.map((announcement, index) => (
              <span key={index} className="text-sm mx-4 flex items-center">
                <a
                  className="text-blue_dark font-semibold flex items-center"
                  href={announcement.link}
                >
                  {announcement.text}
                  <img className="h-4 ml-2" src={newImg} alt="Submit Icon" />
                </a>
              </span>
            ))}
          </div>
        </div>
      </div>
      <About />
      <div className="grid grid-cols-1 lg:grid-cols-8 gap-x-0 mx-2 my-10">
        <div className="m-2 h-[500px] lg:col-span-3 flex flex-col justify-center items-center shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
          <div className="text-white mt-8 bg-blue_dark w-2/3 py-2 text-center rounded-lg text-lg font-semibold transition">
            Important Dates
          </div>
          <Timeline />
        </div>
        <div className="m-4 lg:col-span-3 w-90% bg-blue_dark flex justify-center items-center shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
          <Guidelines />
        </div>
        <div className="m-2 h-[500px] lg:col-span-2 flex flex-col mt-8 items-center shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
          <div className="text-white mt-3 bg-blue_dark w-2/3 py-2 text-center rounded-lg text-lg font-semibold transition">
            Announcements
          </div>
          <ul className="ml-6 mt-4 leading-relaxed">
            {announcements.map((announcement, index) => (
              <li key={index} className="py-2 font-semibold flex items-center">
                <a
                  className="flex items-center"
                  href={announcement.link}
                >
                  {announcement.text}
                  <img className="h-4 ml-2" src={newImg} alt="Submit Icon" />
                </a>
              </li>
            ))}
            <li className="py-2 font-semibold flex items-center">
              <Link to="./author-guide">Check Out the guidelines</Link>
            </li>
          </ul>
        </div>
      </div>
      
      <div className="mx-auto container items-center" id="imp">
        <Sponsor users={keynote} word="Keynote Speaker" />
        {/* <Sponsor users={chair} word="Conference Chair" />
        <Sponsor users={sessionChairs} word="Program Committee Chairs" /> */}
      </div>
      
      <Organization />
      <Footer/> 
      </Transition>
    </>
  );
};

export default Body;
