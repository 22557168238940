import { Footer } from "../components/footer";
import Transition from "../utils/Transition";
import razvan from "../components/assests/keynote/Razvan.png";
import hvtdung from "../components/assests/keynote/hvtdung.jpg";
import tttrang from "../components/assests/keynote/tttrang.png";

const keynotes = [
  {
    name: "Assoc.Prof. Razvan Beuran",
    scope: "Information Technology",
    title: "Applications of AI/ML to Cybersecurity",
    abstract:
      "In recent years, various artificial intelligence (AI) and machine learning (ML) techniques have been applied to the field of cybersecurity. In this presentation, we will introduce several research projects in this area of the Cybersecurity Lab at JAIST. Thus, we will discuss the use of natural language processing (NLP) techniques for the automated analysis of cyber threat intelligence reports, as well as the application of reinforcement learning (RL) techniques for training pentesting agents in realistic network environments.",
    bio: "Razvan Beuran is an Associate Professor at the Japan Advanced Institute of Science and Technology (JAIST), in Ishikawa prefecture, Japan, and he is a member of the Next-Generation Digital Infrastructure Research Area of JAIST that was established in April 2022. His current research focus is Society 5.0 trustworthiness, and he is active in the following areas: cybersecurity, IoT/CPS security, trustworthiness assurance, security education and training.",
    img: razvan,
  },
  {
    name: "Dr. Huynh Vo Trung Dung",
    scope: "Electronics Engineering",
    title:
      "The Synergy of Blockchain and AI in 6G Networks: Opportunities, Challenges, and Prospective Pathways",
    abstract:
      "The rapid advancement of 6G wireless communications has catalyzed the need for robust, scalable, and secure solutions to address the challenges of future mobile networks, such as resource constraints, high complexity in network architectures, and increasing demands for security and privacy. Blockchain and artificial intelligence (AI) emerge as pivotal technologies for overcoming these hurdles, offering the potential for transformative improvements in network efficiency, data privacy, and service reliability. This keynote provides a comprehensive survey of current research on the integration of blockchain and AI within 6G networks. We discuss foundational aspects of both technologies, examine recent advancements in their synergy, and explore applications such as secure services and smart Internet of Things (IoT) applications in wireless communication systems. Key services including spectrum management, computational resource allocation, and security protocols are highlighted. Additionally, emerging use cases in sectors such as healthcare, transportation, and smart grids underscore the versatility of blockchain-AI convergence. The keynote also addresses technical challenges and open research questions, aiming to offer a roadmap for future investigations in this promising field of next-generation wireless communication.",
    bio: "Huynh Vo Trung Dung holds a Ph.D. in Telecommunications from Ghent University, Belgium, and has authored over 20 scientific papers on telecommunications, blockchain, and AI in prestigious international journals. Currently, he is a lecturer and researcher at International University – Vietnam National University Ho Chi Minh City, with research interests in communication signal processing, computer vision, generative AI, and natural language processing. In addition, he serves as a senior consultant at Cybertech JSC, a dynamic and innovative enterprise specializing in generative AI and advanced security solutions.",
    img: hvtdung,
  },
  {
    name: "Ms. Ta Thuy Trang",
    scope: "Civil Engineering",
    title:
      "Machine Learning Algorithms for Predicting Compressive Strength of Normal and High-Early Strength Concrete: A case Study in Binh Thuan, Viet Nam",
    abstract:
      "This study investigates the effectiveness of machine learning models in predicting the compressive strength of both normal and high-early strength concrete. The research, conducted as a case study in Binh Thuan, Vietnam, aims to address the challenges faced by engineers in optimizing concrete mix designs. The Support Vector Machine (SVM) model, in conjunction with regression analysis employing multilinear approaches, yields predictions that are comparatively less accurate than those obtained using deep learning methods such as Artificial Neural Networks (ANN) and Light Gradient Boosting Machine (LightGBM). Particularly, the ANN model exhibits superior predictive performance, boasting an impressive R-squared value of 0.988 and the lowest model error, measured by a Root Mean Square Error of 1.493. Moreover, these deep learning techniques prove adept at capturing the intricate relationship between the water-cementitious material ratio and concrete strength, thereby enhancing the effectiveness of quality control measures at the batching plant. Consequently, engineers are empowered to make precise adjustments to concrete mix proportions during the design phase, leading to a substantial improvement in prediction accuracy and ultimately ensuring the desired performance characteristics of the concrete.",
    bio: "Thuy Trang Ta graduated from Ho Chi Minh City University of Technology in 2008, pursued further studies and obtained a Master's degree from Korea Advanced Institute of Science and Technology (KAIST) in 2010, specializing in research on concrete materials and structures. Since then, I have been working as a lecturer at Saigon Technology University (STU), imparting knowledge and expertise in the field of civil engineering.",
    img: tttrang,
  },
];

const Keynote = () => {
  return (
    <Transition>
      <div className="container mx-auto p-6 mt-[8%] ">
        <h1 className="text-4xl font-bold text-center mb-12 mb-4">
          Keynote Speakers
        </h1>
        <div className="flex flex-col gap-12">
          {keynotes.map((speaker, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-50 dark:text-gray-800 p-8 border border-gray-300 rounded-lg shadow-lg"
            >
              <div className="flex flex-col md:flex-row">
                <div className="flex justify-center w-full md:w-1/3 my-6 pl-16">
                  <img
                    src={speaker.img}
                    alt={speaker.name}
                    className="w-full h-full object-cover rounded-sm"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center md:w-2/3 md:pl-16 ">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-800 mb-2 text-center md:text-left">
                    {speaker.name}
                  </h2>
                  <p className="text-lg font-bold text-blue_super_dark dark:text-gray-600 mb-2 text-center md:text-left">
                    {speaker.scope}
                  </p>
                  <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-600 mb-4 text-center md:text-left">
                    {speaker.title}
                  </h3>
                  <p className="text-sm text-gray-700 dark:text-gray-600 mb-4">
                    {speaker.abstract}
                  </p>
                  <details
                    className="text-sm text-gray-600 dark:text-gray-500"
                    open
                  >
                    <summary className=" text-blue_super_dark">Biography</summary>
                    <p>{speaker.bio}</p>
                  </details>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Transition>
  );
};

export default Keynote;
